<template>
    <div class="wholesalePriceComp" ref="wholesalePriceComp">
        <!-- <div @mousedown.stop="pressCheck" @mouseup.stop="upCheck" @touchstart="pressCheck" @touchend="upCheck"> -->
        <div @click="pressCheck">
            <slot></slot>
        </div>
        <div class="wholesalePrice" v-show="showWholeSalePrice" :style="speStyle">
            <span>批发价：</span>
            <span>￥{{wholesalePrice}}</span>
        </div>
        <div class="pwdBox" v-show="showPwdBox">
            <!-- <input type="password" @input="pwdIn" @keydown="keydown" autocomplete="new-password"/> -->
            <input type="text" @input="pwdIn" @keydown="keydown" autocomplete="new-password"/>
        </div>
    </div>
</template>

<script>
import {getGoodsWholesale} from "@/axios/api"
export default {
    props:['isDetail','isList','goodsId'],
    data(){
        return{
            pwd:[],
            // pwd:"",
            pwdLen:0,
            showPwdBox:false,
            wholesalePrice:"",
            showWholeSalePrice:false,
            speStyle:{},
        }
    },
    methods: {
        pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
        pressCheck(){
            // console.log(123)
            if(localStorage.AOGEYA_userInfo){
                var userInfo=JSON.parse(localStorage.AOGEYA_userInfo);
                if(userInfo.userType==2){
                    if(localStorage.AGY_wholesale_token){
                        var obj={
                            goodsId:this.goodsId,
                            token:localStorage.AGY_wholesale_token
                        }
                        getGoodsWholesale(obj).then(res=>{
                            console.log("获取批发价",res);
                            if(res.code==200){
                                this.wholesalePrice=res.data.wholesalePrice;
                                localStorage.AGY_wholesale_token=res.data.token;
                                this.showWholeSalePrice=true;
                                this.showPwdBox=false;
                            }else if(res.code==401){
                                this.showPwdBox=true;
                            }
                        })
                    }else{
                        this.showPwdBox=true;
                    }
                }
            }
        },
        upCheck(){
            this.showWholeSalePrice=false;
        },
        pwdIn($event){
            // console.log($event.target.value)
            if($event.target.value.length==0){
                this.pwd=[];
            }
            if($event.target.value.length>this.pwdLen){
                this.pwd.push($event.target.value.split('').pop())
            }else{
                this.pwd.splice(this.pwd.length-1)
            }
            this.pwdLen=$event.target.value.length
            var str=$event.target.value.split('').map(t=>'*').join('')
            $event.target.value=str
            console.log(this.pwd)

            // this.pwd=$event.target.value;
        },
        keydown($event){
            // console.log($event)
            if($event.keyCode==13){
                if(this.pwd.length==0){
                    return
                }
                getGoodsWholesale({
                    goodsId:this.goodsId,
                    // password:this.pwd,
                    password:this.pwd.join(''),
                }).then(res=>{
                    console.log("获取批发价",res);
                    if(res.code==200){
                        this.wholesalePrice=res.data.wholesalePrice;
                        localStorage.AGY_wholesale_token=res.data.token;
                        // this.showPwdBox=false;
                        if(this.showPwdBox){
                            this.showPwdBox=false;
                            this.showWholeSalePrice=true;
                        }else{
                            this.showWholeSalePrice=true;                            
                        }
                    }
                })
            }
        },
    },
    created() {
        if(this.isList){
            this.speStyle={
                'margin-top':this.pxToRem(13)+'rem'
            }
        }
        document.body.addEventListener('click',(e)=>{
            // console.log(this.$refs.wholesalePriceComp.contains(e.target));
            if(!this.$refs.wholesalePriceComp.contains(e.target)){
                this.showWholeSalePrice=false;
                this.showPwdBox=false;
            }
        })
    },
}
</script>

<style lang="less" scoped>
.wholesalePriceComp{
    position: relative;
    display: flex;
    .wholesalePrice{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        // color: rgba(255, 70, 28, 1);
        color: #333;
        align-self: center;
        margin-left: 20px;
        &>span:nth-child(2){
            font-size: 19px;
            font-weight: 400;
        }
    }
    .pwdBox{
        position: absolute;
        left: 0;
        top: 45px;
        width: 161px;
        height: 45px;
        background: #fff;
        box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 0 10px;
        input{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 2px;
            // padding-top: 5px;
        }
    }
}
</style>